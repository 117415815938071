<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header align-items-start">
            <div class="card-title">
              <h3 class="card-label">Hisob kitob vedimost qoshish</h3>
            </div>
            <div class="card-element">
              <div>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Sana"
                      append-icon="event"
                      readonly
                      outlined
                      v-bind="attrs"
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="ru"
                    v-model="date"
                    type="month"
                    scrollable
                    dense
                    :max="date2"
                    :first-day-of-week="0"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal = false">Yopish</v-btn>
                    <v-btn color="success" @click="$refs.dialog.save(date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="table-scroll" id="table-scroll">
              <div v-if="loading" class="text-center mt-5">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  label="Large Spinner"
                ></b-spinner>
              </div>
              <div v-if="lists.length !== 0">
                <table v-if="!loading" id="main-table" class="main-table">
                  <thead>
                    <tr>
                      <th rowspan="2">Ф.И.О</th>
                      <th rowspan="2">№</th>
                      <th rowspan="2">Таб №</th>
                      <th rowspan="2">Лавозим</th>
                      <!-- 1 -->
                      <th rowspan="2">Бошлангич колдик</th>
                      <!-- 2 -->
                      <th rowspan="2">Оклад</th>
                      <!-- 3 -->
                      <th rowspan="2">Премия</th>
                      <!-- 4 -->
                      <th rowspan="2">Отпускной</th>
                      <!-- 5 -->
                      <th rowspan="2">Мукофот</th>
                      <!-- 6 -->
                      <th rowspan="2">болничний</th>
                      <!-- 7 -->
                      <th rowspan="2">Премия 2</th>
                      <!-- 8 -->
                      <th rowspan="2">Премия 3</th>
                      <!-- 9 -->
                      <th rowspan="2">Жами хисобланади</th>
                      <!-- 10 -->
                      <th rowspan="2">
                        Даромад солиги
                        <input
                          type="text"
                          v-model="taxPercent"
                          class="w-25px ml-2"
                        />
                        <span>%</span>
                      </th>
                      <!-- 11 -->
                      <th rowspan="2">
                        ИНПС
                        <input
                          type="text"
                          v-model="inpsPercent"
                          class="w-25px ml-2"
                        />
                        <span>%</span>
                      </th>
                      <!-- 12 -->
                      <th rowspan="2">
                        Ижтимои тулов
                        <input
                          type="text"
                          v-model="taxPercent2"
                          class="w-25px ml-2"
                        />
                        <span>%</span>
                      </th>
                      <th rowspan="2">Жарима</th>
                      <!-- 13 -->
                      <th rowspan="2">Жарима 2</th>
                      <!-- 14 -->
                      <th rowspan="2">Жарима 3</th>
                      <!-- 15 -->
                      <th rowspan="2">Жами ушланди</th>
                      <!-- 16 - 17 -->
                      <th colspan="2">Туланди</th>
                      <!-- 18 -->
                      <th rowspan="2">Жами туланди</th>
                      <!-- 19 -->
                      <th rowspan="2">Охирги колдик</th>
                    </tr>
                    <tr class="test">
                      <th>Банк</th>
                      <th>Касса</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in lists" :key="item.id">
                      <th>
                        <span
                          v-if="item.staffpersonal_description !== undefined"
                        >
                          {{ item.staffpersonal_description.first_name }}
                          {{ item.staffpersonal_description.last_name }}
                          {{ item.staffpersonal_description.father_name }}
                        </span>
                      </th>
                      <td>
                        {{ ++index }}
                      </td>
                      <td>
                        {{ item.tabel_number }}
                      </td>

                      <td>
                        <span v-if="item.position_description !== undefined">
                          {{ item.position_description.name }}
                        </span>
                      </td>
                      <!-- 1 -->
                      <td>
                        <span v-if="item.position_description !== undefined">
                          {{ item.staffsalary_starting_saldo | mask }}
                        </span>
                      </td>
                      <!-- 2 -->
                      <td>
                        <span v-if="item.position_description !== undefined">
                          {{ item.position_description.oklad | mask }}
                        </span>
                      </td>
                      <!-- 3 -->
                      <td>
                        <input
                          v-if="item.premiya !== undefined"
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.premiya.total"
                          @input="calc(item)"
                        />
                      </td>
                      <!-- 4 -->
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.vacation_premiye"
                          @input="calc(item)"
                        />
                      </td>
                      <!-- 5 -->
                      <td>
                        <input
                          type="text"
                          v-currency="options"
                          class="w-5em"
                          :id="item.id"
                          v-model="item.gift_premiya"
                          @input="calc(item)"
                        />
                      </td>
                      <!-- 6 -->
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.illnes_premiya"
                          @input="calc(item)"
                        />
                      </td>
                      <!-- 7 -->
                      <!-- additional_premiya1 -->
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.additional_premiya1"
                          @input="calc(item)"
                        />
                      </td>
                      <!-- 8 -->
                      <!-- additional_premiya2 -->
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.additional_premiya2"
                          @input="calc(item)"
                        />
                      </td>
                      <!-- 9 -->
                      <td>
                        <input
                          disabled
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.total_calculated"
                          @input="calcPenalty(item)"
                          @change="calcPenalty(item)"
                        />
                      </td>
                      <!-- 10 -->
                      <td>
                        <input
                          disabled
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.income_tax"
                          @input="calcPenalty(item)"
                          @change="calcPenalty(item)"
                        />
                      </td>
                      <!-- 11 -->
                      <td>
                        <input
                          disabled
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.inps_tax"
                          @input="calcPenalty(item)"
                        />
                      </td>
                      <!-- 12 -->
                      <td>
                        {{ item.social_payment }}
                      </td>
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.penalty"
                          @input="calcPenalty(item)"
                        />
                      </td>
                      <!-- 13 -->
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.additional_penalty1"
                          @input="calcPenalty(item)"
                        />
                      </td>
                      <!-- 14 -->
                      <td>
                        <input
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.additional_penalty2"
                          @input="calcPenalty(item)"
                        />
                      </td>
                      <!-- 15 -->
                      <td>
                        <input
                          disabled
                          type="text"
                          class="w-5em"
                          :id="item.id"
                          v-currency="options"
                          v-model="item.total_subtracted"
                        />
                      </td>
                      <!-- 16 -->
                      <td>
                        <input
                          disabled
                          v-if="item.avans !== undefined"
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.avans.bank.total"
                          @input="calcPeyments(item)"
                        />
                      </td>
                      <!-- 17 -->
                      <td>
                        <input
                          disabled
                          v-if="item.avans !== undefined"
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.avans.cash.total"
                          @input="calcPeyments(item)"
                        />
                      </td>
                      <!-- 18 -->
                      <td>
                        <input
                          disabled
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          v-model="item.total_payed"
                        />
                      </td>
                      <!-- 19 -->
                      <td>
                        <input
                          disabled
                          type="text"
                          class="w-5em"
                          v-currency="options"
                          :id="item.id"
                          :value="item.end_saldo | mask"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="d-flex justify-content-start border-top pt-10">
              <div class="mr-2">
                <v-btn
                  @click="sendData"
                  :disabled="date == '' || newSalaryPayrolLoading"
                  color="success"
                >
                  <i v-if="newSalaryPayrolLoading" class="el-icon-loading"></i>
                  Saqlash
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      newSalaryPayrolLoading: false,
      loading: false,
      lists: [],
      taxPercent: 12,
      taxPercent2: 12,
      inpsPercent: 0.1,
      modal: false,
      date: '',
      date2: new Date().toISOString().substr(0, 7),
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999]
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Ish xaqqi sibotlari' },
      { title: 'Hisob kitob vedimost qoshish' }
    ])
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return val
    }
  },
  watch: {
    list: function (val) {
      if (val.length !== 0) {
        this.lists = []
        this.lists = [...val]
        // this.lists.forEach(x => (x.social_payment = 0))
        this.loading = false
      }
    },
    date: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.loading = true
        const data = {
          month: val.split('-')[1],
          year: val.split('-')[0]
        }
        this.$store.dispatch('getStaffEmployedListForMonth', data)
      }
    },
    taxPercent() {
      this.lists.forEach((x) => {
        this.calc(x)
      })
    },
    taxPercent2() {
      this.lists.forEach((x) => {
        this.calc(x)
      })
    },
    inpsPercent() {
      this.lists.forEach((x) => {
        this.calc(x)
      })
    }
  },
  computed: {
    list() {
      const data = this.$store.state.requests.staffEmployedListForMonth
      data.forEach((x) => (x.social_payment = 0))
      console.log('123123', data)
      return data
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    }
  },
  methods: {
    calc(val) {
      val.social_payment = 0
      val.total_calculated =
        val.staffsalary_starting_saldo +
        val.position_description.oklad +
        this.toFloat(val.illnes_premiya) +
        this.toFloat(val.gift_premiya) +
        this.toFloat(val.vacation_premiye) +
        this.toFloat(val.premiya.total) +
        this.toFloat(val.additional_premiya1) +
        this.toFloat(val.additional_premiya2)
      val.income_tax =
        (val.total_calculated * this.taxPercent) / 100 -
        (val.total_calculated * this.inpsPercent) / 100
      val.inps_tax = (val.total_calculated * this.inpsPercent) / 100
      val.social_payment = (val.total_calculated * this.taxPercent2) / 100
      val.total_calculated = val.total_calculated.toLocaleString('es-US')
      val.social_payment = val.social_payment.toLocaleString('es-US')
      val.income_tax = Math.round(val.income_tax * 100) / 100
      val.income_tax = val.income_tax.toLocaleString('es-US')
      val.inps_tax = val.inps_tax.toLocaleString('es-US')
      this.calcPenalty(val)
      this.calcPeyments(val)
      this.calcTotal(val)
    },
    calcPenalty(val) {
      val.total_subtracted =
        this.toFloat(val.income_tax) +
        this.toFloat(val.inps_tax) +
        this.toFloat(val.penalty) +
        this.toFloat(val.additional_penalty1) +
        this.toFloat(val.additional_penalty2)
      val.total_subtracted = val.total_subtracted.toLocaleString('es-US')
      this.calcPeyments(val)
    },
    calcPeyments(val) {
      val.total_payed =
        this.toFloat(val.avans.cash.total) + this.toFloat(val.avans.bank.total)
      val.total_payed = val.total_payed.toLocaleString('es-US')
      this.calcTotal(val)
    },
    calcTotal(val) {
      val.end_saldo =
        this.toFloat(val.total_calculated) -
        this.toFloat(val.total_subtracted) -
        this.toFloat(val.total_payed)
      val.end_saldo.toLocaleString('es-US')
    },
    toFloat(val) {
      if (typeof val == 'string') {
        return parseFloat(val.replace(/[ ,]/g, ''))
      } else if (typeof val == 'number') return val
      else return 0
    },
    checkAvans(val) {
      try {
        const newArr = [...val.avans.bank.ids, ...val.avans.cash.ids]
        return newArr
      } catch {
        return []
      }
    },
    checkPremiya(val) {
      try {
        const newArr = [...val.premiya.ids]
        return newArr
      } catch {
        return []
      }
    },
    // check() {
    //   const d = new Date(this.date.slice(0, 4), this.date.slice(5, 7) + 1, 0)
    //   console.log(d)
    //   console.log(this.date.slice(5, 7))
    // },
    sendData() {
      const data = {
        payrol: {
          oper_date: this.date + '-01'
        },
        payrol_elements: []
      }

      this.lists.forEach((element) => {
        const data2 = {
          staffemployed_id: element.id,
          social_payment: this.toFloat(element.social_payment),
          tabel_number: element.tabel_number,
          postion: element.position_description.name,
          oklad: element.position_description.oklad,
          start_saldo: this.toFloat(element.staffsalary_starting_saldo),
          premiya: this.toFloat(element.premiya.total),
          vacation_premiye: this.toFloat(element.vacation_premiye),
          gift_premiya: this.toFloat(element.gift_premiya),
          illnes_premiya: this.toFloat(element.illnes_premiya),
          additional_premiya1: this.toFloat(element.additional_premiya1),
          additional_premiya2: this.toFloat(element.additional_premiya2),
          total_calculated: this.toFloat(element.total_calculated),
          income_tax: this.toFloat(element.income_tax),
          inps_tax: this.toFloat(element.inps_tax),
          penalty: this.toFloat(element.penalty),
          additional_penalty1: this.toFloat(element.additional_penalty1),
          additional_penalty2: this.toFloat(element.additional_penalty2),
          total_subtracted: this.toFloat(element.total_subtracted),
          total_payed_from_bank: this.toFloat(element.avans.bank.total),
          total_payed_from_kassa: this.toFloat(element.avans.cash.total),
          total_payed: this.toFloat(element.total_payed),
          avans: this.checkAvans(element),
          primeya_ids: this.checkPremiya(element),
          end_saldo: this.toFloat(element.end_saldo)
        }
        data.payrol_elements.push(data2)
      })
      console.log(data)
      this.newSalaryPayrolLoading = true
      this.$store
        .dispatch('payrolCreate', data)
        .then(() => {
          this.newSalaryPayrolLoading = false
        })
        .catch((err) => {
          this.newSalaryPayrolLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style scoped>
.w-25px {
  width: 25px;
}
.card-body-table table {
  white-space: nowrap;
}
table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
table tbody tr:nth-child(odd):hover {
  background-color: #f2f2f2;
}
.main-table table thead tr td {
  font-weight: bold;
}
.card-body-table table thead tr th,
.card-body-table table tbody tr th {
  text-align: center;
}
.card-body-table table tr p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  /* height: 600px;  */
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #9f9f9f;
  text-align: left;
  padding: 5px;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 600px;
}
.table-scroll table {
  width: 100%;
  white-space: nowrap;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th:first-child {
  background-color: rgb(255, 255, 255);
}
.table-scroll th:first-child {
}
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  vertical-align: middle;
}
.table-scroll thead th {
  color: #000;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
}
a:focus {
  background: red;
} /* testing links*/

th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
.table-scroll thead tr:nth-child(2) th {
  top: 30px;
  z-index: 2;
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}
.card.card-custom > .card-header:not(.flex-nowrap) {
  padding-top: 1.5rem !important;
  padding-bottom: 0 !important;
}
</style>
